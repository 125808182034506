import {
  AlertIcon,
  Badge,
  Box,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Circle,
  Divider,
  Flex,
  Hide,
  HStack,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Skeleton,
  Stack,
  Switch,
  Tag,
  Tbody,
  Text,
  Thead,
  Tooltip,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Column,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

import {
  CalendarIcon,
  CheckIcon,
  EmailIcon,
  ExternalLinkIcon,
  TimeIcon,
  WarningIcon,
} from "@chakra-ui/icons";
import styled from "@emotion/styled";

import { DateRangeType, Resource } from "stores/queries/area";
import theme from "theme/theme";
import { MowiIcon, MowiIcons } from "ui/MowiIcon/Icon";
import { AddRangeMutation } from "components/Poi/types";
import Calendar from "react-calendar";

let structures = [
  {
    name: "Hotel Sole ***",
    from: new Date(2024, 9, 10), // 10 ottobre 2024
    openings: new Array(100).fill(true), // Aperture casuali
  },
  {
    name: "Camping Mare ***",
    from: new Date(2024, 9, 10),
    openings: [true, true, true, true, null, true, false, null, true, false],
  },
  {
    name: "B&B Luna **",
    from: new Date(2024, 9, 10),
    openings: [true, true, false, false, null, true, true, true, false, false],
  },
  {
    name: "Hotel gianni ***",
    from: new Date(2024, 9, 10), // 10 ottobre 2024
    openings: new Array(67).fill(true), // Aperture casuali
  },
  {
    name: "Hotel gianni ***",
    from: new Date(2024, 9, 10), // 10 ottobre 2024
    openings: new Array(67).fill(true), // Aperture casuali
  },
  {
    name: "Hotel gianni ***",
    from: new Date(2024, 9, 10), // 10 ottobre 2024
    openings: new Array(67).fill(true), // Aperture casuali
  },
  {
    name: "Hotel gianni ***",
    from: new Date(2024, 9, 10), // 10 ottobre 2024
    openings: new Array(67).fill(true), // Aperture casuali
  },
  {
    name: "Hotel gianni ***",
    from: new Date(2024, 9, 10), // 10 ottobre 2024
    openings: new Array(67).fill(true), // Aperture casuali
  },
  {
    name: "Hotel gianni ***",
    from: new Date(2024, 9, 10), // 10 ottobre 2024
    openings: new Array(67).fill(true), // Aperture casuali
  },
  {
    name: "Hotel gianni ***",
    from: new Date(2024, 9, 10), // 10 ottobre 2024
    openings: new Array(67).fill(true), // Aperture casuali
  },
  {
    name: "Hotel gianni ***",
    from: new Date(2024, 9, 10), // 10 ottobre 2024
    openings: new Array(67).fill(true), // Aperture casuali
  },
  {
    name: "Hotel gianni ***",
    from: new Date(2024, 9, 10), // 10 ottobre 2024
    openings: new Array(67).fill(true), // Aperture casuali
  },
  {
    name: "Hotel gianni ***",
    from: new Date(2024, 9, 10), // 10 ottobre 2024
    openings: new Array(67).fill(true), // Aperture casuali
  },
  {
    name: "Hotel gianni ***",
    from: new Date(2024, 9, 10), // 10 ottobre 2024
    openings: new Array(67).fill(true), // Aperture casuali
  },
  {
    name: "Hotel gianni ***",
    from: new Date(2024, 9, 10), // 10 ottobre 2024
    openings: new Array(111).fill(false), // Aperture casuali
  },
  {
    name: "Hotel gianni ***",
    from: new Date(2024, 9, 10), // 10 ottobre 2024
    openings: new Array(111).fill(false), // Aperture casuali
  },
  {
    name: "Hotel gianni ***",
    from: new Date(2024, 9, 10), // 10 ottobre 2024
    openings: new Array(111).fill(false), // Aperture casuali
  },
  {
    name: "Hotel gianni ***",
    from: new Date(2024, 9, 10), // 10 ottobre 2024
    openings: new Array(111).fill(false), // Aperture casuali
  },
  {
    name: "Hotel gianni ***",
    from: new Date(2024, 9, 10), // 10 ottobre 2024
    openings: new Array(111).fill(false), // Aperture casuali
  },
];

type Structure = {
  name: string;
  from: Date;
  openings: (boolean | null)[];
};

type Props = {
  structures: Structure[];
};

// Styled components usando @emotion/styled
const GridContainer = styled.div`
  display: grid;
  overflow-y: auto; /* Abilita lo scroll verticale */
  overflow-x: auto; /* Abilita lo scroll verticale */
  max-height: 100%; /* Altezza del container al 100% del genitore */
  max-width: 100%;
  margin: 0 auto;
  position: relative;
`;

const MonthHeader = styled.div`
  border-left: 1px solid ${theme.colors.gray[200]};
  background-color: ${theme.colors.gray[50]};
  position: sticky;
  top: 0;
  height: 30px;
  z-index: 2;
`;

const HeaderCell = styled.div<{
  isHovered?: boolean;
  isFirstOfMonth?: boolean;
}>`
  border: 0px solid #e0e0e0;
  padding: 0;
  background-color: ${({ isHovered }) =>
    isHovered ? theme.colors.yellow[200] : "#f8f9fa"};
  position: sticky;
  top: 30px;
  z-index: 2;

  ${({ isFirstOfMonth }) =>
    isFirstOfMonth &&
    `
      font-weight: bold !important;
      border: 0px solid ${theme.colors.blue[200]};
      background-color: ${theme.colors.blue[100]};
  `}
`;

const RowHeader = styled.div<{ isHovered?: boolean }>`
  border: 0px solid #e0e0e0;
  text-align: left;
  padding: 0 5px;
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: ${({ isHovered }) =>
    isHovered ? theme.colors.yellow[200] : "#f8f9fa"};
  white-space: nowrap;
`;

const Cell = styled.div<{ status: boolean | null; isHovered?: boolean }>`
  cursor: pointer;
  border: 1px solid #fff;
  background-color: ${({ status }) =>
    status === true
      ? theme.colors.green[300]
      : status === false
      ? theme.colors.pink[300]
      : "#e0e0e0"};

  &:hover {
    background-color: ${theme.colors.yellow[200]};
  }
`;

const CountCell = styled.div`
  background-color: ${theme.colors.gray[50]};
`;

const Grid: React.FC<Props> = ({ structures }) => {
  const [hoveredCol, setHoveredCol] = useState<number | null>(null);
  const [hoveredRow, setHoveredRow] = useState<number | null>(null);

  // Funzione per formattare la data in "gg mmm"
  // Funzione per formattare la data in "gg mmm"
  const formatDate = (date: Date): string[] => {
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      weekday: "short",
      month: "short",
    };
    const formattedDate = date.toLocaleDateString("it-IT", options).split(" ");
    return formattedDate; // Restituisce un array [gg, mmm]
  };

  const getDayFromDate = (start: Date, index: number): Date => {
    const result = new Date(start);
    result.setDate(result.getDate() + index);
    return result; // Restituisce la data
  };

  // Trova il numero massimo di aperture tra tutte le strutture
  const maxOpenings = Math.max(
    ...structures.map((structure) => structure.openings.length)
  );

  // Funzione per contare quanti hotel sono aperti in un determinato giorno (colonna)
  const countOpenHotels = (dayIndex: number): number => {
    return structures.reduce((count, structure) => {
      if (structure.openings[dayIndex] === true) {
        return count + 1;
      }
      return count;
    }, 0);
  };

  // Raggruppamento delle date per mese
  const groupByMonth = (start: Date) => {
    const months: { [key: string]: number[] } = {}; // Esempio: { "Jan": [0, 1, 2, ...] }

    for (let i = 0; i < maxOpenings; i++) {
      const date = getDayFromDate(start, i);
      const month = date.toLocaleString("it-IT", { month: "long" });

      if (!months[month]) {
        months[month] = [];
      }

      months[month].push(i); // Aggiunge l'indice della colonna al mese
    }

    return months;
  };

  const monthsGrouped = groupByMonth(structures[0].from);

  return (
    <GridContainer
      style={{
        gridTemplateColumns: `auto repeat(${maxOpenings}, 23px)`,
        paddingBottom: "20px",
      }}
    >
      <RowHeader
        style={{
          position: "sticky",
          top: 0,
          zIndex: 4,
          /* backgroundColor: theme.colors.gray[100], */
        }}
      >
        <Box
          style={{ backgroundColor: theme.colors.gray[50], zIndex: 4 }}
        ></Box>
      </RowHeader>
      {/* Empty cell for alignment */}
      {Object.entries(monthsGrouped).map(([month, days], index) => (
        <MonthHeader
          key={index}
          style={{
            gridColumn: `span ${days.length}`,
          }}
        >
          <Text
            mt={1}
            align={"center"}
            fontWeight={600}
            textTransform={"uppercase"}
          >
            {month}
          </Text>
        </MonthHeader>
      ))}
      <RowHeader
        style={{
          position: "sticky",
          top: 30,
          zIndex: 4,
          /* backgroundColor: theme.colors.gray[100], */
        }}
      >
        <Box
          style={{ backgroundColor: theme.colors.gray[50], zIndex: 4 }}
        ></Box>
      </RowHeader>
      {Array.from({ length: maxOpenings }).map((_, index) => {
        const date = getDayFromDate(structures[0].from, index);
        const [weekday, day, month] = formatDate(date);
        const isFirstOfMonth = date.getDate() === 1; // Verifica se è il primo del mese

        return (
          <HeaderCell
            key={index}
            isHovered={hoveredCol === index}
            isFirstOfMonth={isFirstOfMonth}
            onMouseEnter={() => setHoveredCol(index)}
            onMouseLeave={() => setHoveredCol(null)}
          >
            <VStack spacing={0} py={1} pt={2}>
              <Text
                color={"gray.600"}
                fontSize="13px"
                fontWeight={600}
                lineHeight={1}
                mr={0.5}
              >
                {day}
              </Text>
              <Text
                textTransform={"uppercase"}
                color={"gray.600"}
                fontSize="8px"
                mr={0.5}
              >
                {weekday}
              </Text>
            </VStack>
          </HeaderCell>
        );
      })}
      {structures.map((structure, rowIndex) => (
        <>
          <RowHeader
            key={structure.name}
            isHovered={hoveredRow === rowIndex}
            onMouseEnter={() => setHoveredRow(rowIndex)}
            onMouseLeave={() => setHoveredRow(null)}
          >
            <Text fontSize={12} lineHeight={1.2} p={1} align={"left"}>
              {structure.name}
            </Text>
          </RowHeader>
          {Array.from({ length: maxOpenings }).map((_, colIndex) => (
            <Cell
              key={colIndex}
              status={structure.openings[colIndex]}
              onMouseEnter={() => {
                setHoveredCol(colIndex);
                setHoveredRow(rowIndex);
              }}
              onMouseLeave={() => {
                setHoveredCol(null);
                setHoveredRow(null);
              }}
            />
          ))}
        </>
      ))}
      {/* Riga di conteggio degli hotel aperti per ogni giorno */}
      <RowHeader>
        <Text pl={1} fontWeight={600} fontSize={12}>
          Aperti
        </Text>
      </RowHeader>
      {Array.from({ length: maxOpenings }).map((_, index) => (
        <CountCell key={index}>
          <Text
            align={"center"}
            fontWeight={600}
            fontSize={12}
            color={"gray.600"}
          >
            {countOpenHotels(index)}
          </Text>
        </CountCell>
      ))}
    </GridContainer>
  );
};

interface OpeningTableProps {
  data: Resource[];
  addRange: AddRangeMutation;
  title: string;
  loading: any;
  itemPath: string;
  icon: MowiIcons;
  type: "track" | "transport" | "poi" | "accomodation";
}

const OpeningTable = (props: OpeningTableProps) => {
  const {
    isOpen: isFromOpen,
    onOpen: fromOpen,
    onClose: fromClose,
  } = useDisclosure();
  const {
    isOpen: isToOpen,
    onOpen: toOpen,
    onClose: toClose,
  } = useDisclosure();

  const [operation, setOperation] = useState<0 | 1 | 2>(0);

  const [dateRange, setDateRange] = useState<DateRangeType>({
    start: (() => {
      const date = new Date();
      date.setDate(date.getDate() - 15); // Imposta a 15 giorni fa
      return date;
    })(),
    end: (() => {
      const date = new Date();
      date.setMonth(date.getMonth() + 4); // Imposta a 4 mesi in avanti
      return date;
    })(),
  });

  const onChangeStartRange = (date: any) => {
    if (date.getTime() > dateRange.end.getTime()) {
      setDateRange({ start: date, end: date });
    } else setDateRange({ start: date, end: dateRange.end });
    fromClose();
    toOpen();
  };

  return (
    <>
      <Card
        direction="column"
        w="100%"
        h={"100%"}
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
        overflowY={"hidden"}
        paddingBottom={"0px !important"}
        bg={"white"}
        borderRadius={30}
        boxShadow="14px 17px 40px 4px rgba(112, 144, 176, 0.08)"
      >
        <Flex px="25px" justify="space-between" mt={5} mb={5} align="center">
          <Flex align="center" color={"brand.500"}>
            <MowiIcon name={props.icon} size={30} />
            <Text
              color={"gray.700"}
              fontSize="22px"
              fontWeight="700"
              lineHeight="100%"
            >
              {props.title}
            </Text>
          </Flex>

          <Stack
            flex={1}
            alignItems={"center"}
            justifyContent={"flex-end"}
            direction={["column", "row"]}
            hideBelow={"md"}
          >
            <VStack alignItems={"center"}>
              <Popover
                isOpen={isFromOpen}
                onOpen={fromOpen}
                onClose={fromClose}
              >
                <PopoverTrigger>
                  <Button variant="outline" leftIcon={<CalendarIcon />}>
                    {dateRange.start.toLocaleDateString()}
                  </Button>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>A partire dal</PopoverHeader>
                  <PopoverBody>
                    <Calendar
                      onChange={onChangeStartRange}
                      value={dateRange.start}
                    />
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </VStack>

            <Text fontSize="xs" as="b">
              -
            </Text>

            <VStack>
              <Popover isOpen={isToOpen} onOpen={toOpen} onClose={toClose}>
                <PopoverTrigger>
                  <Button variant="outline" leftIcon={<CalendarIcon />}>
                    {dateRange.end.toLocaleDateString()}
                  </Button>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>Fino al</PopoverHeader>
                  <PopoverBody>
                    <Calendar
                      minDate={dateRange.start}
                      onChange={(date: any) => {
                        setDateRange({ end: date, start: dateRange.start });
                        toClose();
                      }}
                      value={dateRange.end}
                    />
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </VStack>
          </Stack>

          <ButtonGroup ml={3} spacing="2">
            <Button
              as={RouterLink}
              to={"/accomodations"}
              onClick={() => {}}
              colorScheme="brand"
              variant={"outline"}
            >
              Lista
            </Button>
          </ButtonGroup>

          {/*  <Menu /> */}
        </Flex>

        <Flex height={"calc(100vh - 350px)"} overflowY={"auto"}>
          <Box
            style={{ borderTopWidth: 1 }}
            w="100%"
            h="100%"
            backgroundColor={theme.colors.gray[100]}
          >
            <Grid structures={structures} />
          </Box>
        </Flex>
      </Card>
    </>
  );
};

export { OpeningTable };
