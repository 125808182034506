import { useQuery } from "@apollo/client";
import { Box, Stack } from "@chakra-ui/react";
import { AccomodationReport } from "components/Accomodation/AccomodationReport";
import { PoiReport } from "components/Poi/PoiReport";
import { ACTUAL_AUTH } from "stores/db/auth";
import { ACCOMODATIONS_GET_STATS } from "stores/queries/accomodations";
import { POIS_GET_STATS } from "stores/queries/pois";

const Dashboard = () => {
  const { data: poiStatsData, loading: poiLoading } = useQuery(POIS_GET_STATS);
  const { data: accomodationStatsData, loading: accomodationLoading } =
    useQuery(ACCOMODATIONS_GET_STATS);

  // Chakra Color Mode
  return (
    <Box>
      <Stack spacing={4} maxW="1800px">
        {/* <TrackReport /> */}
        <PoiReport
          statistics={poiStatsData?.adminPoiGetStatistics?.statistics}
          emailStatistics={poiStatsData?.adminPoiGetStatistics?.emailStatistics}
          loading={poiLoading}
          area={ACTUAL_AUTH.area}
        />
        {/* <AccomodationReport
          statistics={
            accomodationStatsData?.adminAccomodationGetStatistics?.statistics
          }
          emailStatistics={
            accomodationStatsData?.adminAccomodationGetStatistics
              ?.emailStatistics
          }
          loading={accomodationLoading}
          area={ACTUAL_AUTH.area}
        /> */}
        {/* <TransportReport /> */}
      </Stack>

      {/* <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
        <TotalSpent />
        <WeeklyRevenue />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
        <CheckTable columnsData={columnsDataCheck} tableData={tableDataCheck} />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
          <DailyTraffic />
          <PieCard />
        </SimpleGrid>
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
        <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
          <Tasks />
          <MiniCalendar h='100%' minW='100%' selectRange={false} />
        </SimpleGrid>
      </SimpleGrid> */}
    </Box>
  );
};

export { Dashboard };
