import { useQuery } from "@apollo/client";
import { Box, Heading, VStack } from "@chakra-ui/react";
import { PoiReport } from "components/Poi/PoiReport";
import React from "react";
import { ETgpAreaToName } from "stores/queries/area";
import { POIS_GET_ALLAREAS_STATS } from "stores/queries/pois";

const AdminDashboard = () => {
  const { data, loading } = useQuery(POIS_GET_ALLAREAS_STATS);
  return (
    <Box w="full">
      <VStack alignItems="stretch" maxW="1800px" spacing={3}>
        {data?.poiGetAllAreasStatistics?.map((area) => {
          return (
            <>
              {area?.area && (
                <Heading
                  size="lg"
                  lineHeight={1}
                  color="tgpBlue"
                  textShadow="xl"
                  mt={1}
                  ml={2}
                >
                  {ETgpAreaToName[area?.area as keyof typeof ETgpAreaToName]}
                </Heading>
              )}
              <PoiReport
                key={area?.area}
                statistics={area?.statistics}
                emailStatistics={area?.emailStatistics}
                loading={loading}
                area={area?.area}
              />
            </>
          );
        })}
      </VStack>
    </Box>
  );
};

export { AdminDashboard };
