import React from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Flex,
  Text,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Box,
  Heading,
  useMediaQuery,
  Skeleton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Input,
  ModalFooter,
  Button,
  useToast,
  IconButton,
} from "@chakra-ui/react";

import {
  CheckCircleIcon,
  CalendarIcon,
  ExternalLinkIcon,
  EditIcon,
} from "@chakra-ui/icons";

import {
  ACCOMODATION,
  //ACCOMODATIONS_CALENDAR_ADDRANGE_OPERATOR,
  ACCOMODATION_DAYS,
  ACCOMODATIONS_CALENDAR_ADDRANGE,
  //ACCOMODATION_UPDATE,
} from "stores/queries/accomodations";
import { useParams } from "react-router-dom";
import { MowiIcon } from "ui/MowiIcon/Icon";
/* import { AccomodationStatus } from "components/Accomodation/AccomodationStatus";

import { AccomodationCalendar } from "components/Accomodation/AccomodationCalendar"; */
import { CalendarDay, Resource } from "stores/queries/area";
//import { AccomodationOperatorLink } from "components/Accomodation/AccomodationOperatorLink";
import { ShepherdTour, ShepherdTourContext } from "react-shepherd";
import shepherdConfig from "./Tour/AccomodationSteps";
import { secure } from "stores/db/appstate";
import { OpeningYearCalendar } from "components/Calendar/OpeningYearCalendar";

function transformArray(
  startDate: string,
  availabilityArray: (boolean | undefined | null)[]
): CalendarDay[] {
  // Funzione per aggiungere giorni alla data
  function addDays(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  const transformedArray = availabilityArray.map((isOpen, index) => {
    // Calcola la data corrente partendo dalla data iniziale
    const currentDate = addDays(new Date(startDate), index);

    // Ottieni i valori per anno, mese e giorno nel fuso orario locale
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");

    const dayString = `${year}-${month}-${day}`;

    // Determina il valore del campo "schedules"
    let schedules: string[] | null;
    if (isOpen === true) {
      schedules = ["00:00-24:00"];
    } else if (isOpen === false) {
      schedules = [];
    } else {
      schedules = null;
    }

    // Ritorna l'oggetto
    return {
      day: dayString,
      schedules: schedules,
    };
  });

  return transformedArray;
}

const YearTab = (props: { _id: string }) => {
  const [getCalendar, { loading: gcLoading }] = useLazyQuery(
    ACCOMODATION_DAYS,
    {
      context: { headers: { secureCode: `${secure()}` } },
      fetchPolicy: "network-only",
    }
  );
  const getDays = async (data: {
    variables: {
      from: string;
      to: string;
      _id: string;
    };
  }) => {
    let days = null;

    try {
      const res = await getCalendar({
        variables: {
          from: data.variables.from,
          to: data.variables.to,
          id: data.variables._id,
        },
      });

      days = transformArray(
        data.variables.from,
        res?.data?.adminAccomodationGetDays
      );

      console.log(data.variables.from, res?.data?.adminAccomodationGetDays);
    } catch (error) {
      console.log(error);
    }

    return days;
  };

  const [addRange, { loading: aRLoading, error: arError, data: arData }] =
    useMutation(ACCOMODATIONS_CALENDAR_ADDRANGE, {
      context: { headers: { secureCode: `${secure()}` } },
    });

  return (
    <OpeningYearCalendar
      calendarMeta={{}}
      _id={props._id}
      addRange={addRange}
      getDays={getDays}
    />
  );
};

const AccomodationDetail = () => {
  let { _id } = useParams();

  const toast = useToast();
  const [isMobile] = useMediaQuery("(max-width: 600px)");
  const {
    isOpen: isEditingEmail,
    onOpen: onEditingEmail,
    onClose: offEditingEmail,
  } = useDisclosure();
  const [newEmail, setNewEmail] = React.useState<string>("");

  const { data, loading } = useQuery(ACCOMODATION, {
    variables: { _id },
    onCompleted: (data) => {
      if (newEmail == "" && !isEditingEmail)
        setNewEmail(data?.adminAccomodation?.email || "");
    },
  });
  /*   const [triggerEmailChange] = useMutation(ACCOMODATION_UPDATE, {
    refetchQueries: [{ query: ACCOMODATION, variables: { _id } }],
  });
 */
  const triggerEmailChange: any = () => {};

  const saveNewEmail = () => {
    triggerEmailChange({
      variables: {
        id: _id,
        accomodation: {
          email: newEmail,
        },
      },
    })
      .then(() => {
        toast({
          title: "Email aggiornata",
          description: "L'email è stata aggiornata correttamente",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        offEditingEmail();
      })
      .catch((err: any) => {
        console.log(err);
        toast({
          title: "Errore",
          description:
            "Si è verificato un errore nell'aggiornamento dell'email",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };
  return (
    <Box w={"100%"} position={"relative"}>
      <Modal isOpen={isEditingEmail} onClose={offEditingEmail}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Modifica email</ModalHeader>
          <ModalBody>
            <Input
              type="email"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
            />
          </ModalBody>
          <ModalFooter gap={3}>
            <Button variant={"outline"} onClick={offEditingEmail}>
              Chiudi
            </Button>
            <Button colorScheme="brand" onClick={saveNewEmail}>
              Salva
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* <ShepherdTour tourOptions={shepherdConfig.tourOptions} steps={[]}>
        <StartTourButton />
      </ShepherdTour> */}
      {/* <Button onClick={() => tour.current?.start()}>Start tour</Button> */}
      <Tabs
        variant="soft-rounded"
        boxShadow="14px 17px 40px 4px rgba(112, 144, 176, 0.08)"
        borderRadius={30}
        //defaultIndex={1}
        filter="auto"
        transition={"all"}
        isLazy
      >
        <Box
          flex={1}
          py={3}
          px={5}
          color={"white"}
          borderTopRadius={30}
          justifyContent={"space-between"}
          flexDirection="row"
          bg="tgpBlue"
        >
          <TabList
            alignItems={"center"}
            justifyContent={"center"}
            flexDirection={isMobile ? "column" : "row"}
          >
            <MowiIcon name="my_hotel" size={35} />
            <Flex
              flexDirection={"column"}
              flex={1}
              alignItems={isMobile ? "center" : "flex-start"}
            >
              <Skeleton isLoaded={!loading && !!data}>
                <Heading noOfLines={1} size={isMobile ? "sm" : "md"} flex={1}>
                  {data?.adminAccomodation?.name?.find(
                    (n) => n.langIso === "it"
                  )?.value ||
                    data?.adminAccomodation?.name[0]?.value ||
                    ""}
                </Heading>
              </Skeleton>
              <Skeleton
                isLoaded={data?.adminAccomodation?.owner ? true : false}
                fadeDuration={2}
              >
                <Text noOfLines={1} size={isMobile ? "xs" : "sm"} flex={1}>
                  {data?.adminAccomodation?.owner}
                </Text>
                <Flex
                  id="email-edit"
                  alignItems={"center"}
                  cursor="accomodationnter"
                  onClick={onEditingEmail}
                  py={"0.5"}
                  _hover={{
                    filter: "brightness(0.9)",
                  }}
                >
                  <Text noOfLines={1}>
                    {data?.adminAccomodation?.email || "Indirizzo email"}
                  </Text>
                  <EditIcon ml={2} />
                </Flex>
              </Skeleton>
            </Flex>
            <Flex flexDirection={"row"} mt={isMobile ? 5 : 0}>
              <Tab isDisabled={true} color={"white"} className="operator-tab">
                {isMobile ? <ExternalLinkIcon /> : "Operatore"}
              </Tab>
            </Flex>
          </TabList>
        </Box>

        <TabPanels p={0} bg={"white"} borderBottomRadius={30}>
          <TabPanel p={0} pt={2}>
            <YearTab _id={_id} />
          </TabPanel>
          {/* <TabPanel>
            <WarningsList type="ACCOMODATION" _id={_id} />
          </TabPanel> */}
          {/*       <TabPanel>
            <AccomodationOperatorLink _id={_id} />
          </TabPanel> */}
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export { AccomodationDetail };

const StartTourButton = () => {
  const tour = React.useContext(ShepherdTourContext);
  const steps = React.useMemo(() => shepherdConfig.steps(tour), [tour]);
  tour.addSteps(steps);
  return (
    <IconButton
      aria-label="Help"
      pos="fixed"
      rounded="full"
      width={12}
      height={12}
      bg="brand.500"
      bottom={5}
      right={5}
      color="white"
      //shadow={"xl"}
      onClick={() => {
        tour.start();
      }}
      _hover={{
        filter: "brightness(0.9)",
      }}
      icon={
        <span
          style={{
            fontSize: "1.5rem",
          }}
        >
          ?
        </span>
      }
    />
  );
};
