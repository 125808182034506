import { Box, HStack, Image, Text, useDisclosure } from "@chakra-ui/react";
import AdminNavbar from "components/Navbar/NavbarAdmin";
import { Sidebar } from "components/Sidebar/Sidebar";
import { Routes, Route, Navigate } from "react-router-dom";
import { useQuery } from "@apollo/client";

import LOGO from "./assets/img/logo-bianco.png";
import { APP_STATE, APP_STATE_PROPS } from "stores/queries/appstate";
import { Login } from "views/auth/login";
import { TracksList } from "views/admin/tracks/TracksList";
import { TrackDetail } from "views/admin/tracks/TrackDetail";
import { Dashboard } from "views/admin/Dashboard";
import { Openings } from "views/operator/Openings";
import { PoisList } from "views/admin/pois/PoisList";
import { TransportsList } from "views/admin/transports/TransportsList";
import { PoiDetail } from "views/admin/pois/PoiDetail";
import { TransportDetail } from "views/admin/transports/TransportDetail";
import { UsersList } from "views/admin/users/UsersList";
import { AdminDashboard } from "views/admin/AdminDashboard";
import { InfoPointLink } from "views/admin/infos/InfoPointLink";
import { PoisInfos } from "views/infos/PoisInfos";
import { AccomodationsList } from "views/admin/accomodations/AccomodationsList";
import { AccomodationDetail } from "views/admin/accomodations/AccomodationDetail";
import { AccomodationsListOpening } from "views/admin/accomodations/AccomodationsListOpening";

/* const getActiveRoute = (routes: NavRoute[]) => {
  const activeRoute = "Default Brand Text";
  for (let i = 0; i < routes.length; i++) {
    if (window.location.pathname.includes(routes[i].path)) {
      return routes[i].name;
    }
  }
  return activeRoute;
}; */

const AuthRouter = () => {
  return (
    <>
      <Box
        position={"absolute"}
        zIndex={1}
        w="100%"
        h="400px"
        bgGradient="linear(to-br, brand.500, brand.600)"
      />
      <Box
        flex={1}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Image
          src={LOGO}
          height={120}
          position={{ base: "relative", xl: "absolute" }}
          left={{ base: undefined, xl: 10 }}
          top={10}
          mb={5}
          zIndex={10}
        />
        <Login />
        <Text color={"gray.400"}>
          © Trentino Marketing s.p.a - Mowi Space s.r.l.
        </Text>
      </Box>
    </>
  );
};

const AdminRouter = () => {
  const { onOpen } = useDisclosure();
  return (
    <>
      <Sidebar />
      <AdminNavbar
        onOpen={onOpen}
        brandText={"Calendario"}
        message={"Calendar"}
      />
      <Box
        mx="auto"
        p={{ base: "20px", md: "30px" }}
        pe="20px"
        minH="100vh"
        pt={{ base: "210px", md: "150px", xl: "150px" }}
        w={{ base: "100%", xl: "calc( 100% - 300px )" }}
        maxWidth={{ base: "100%", xl: "calc( 100% - 300px )" }}
        float="right"
      >
        <Routes>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="overview" element={<AdminDashboard />} />
          {/* <Route path="tracks">
            <Route path="" element={<TracksList />} />
            <Route path=":_id" element={<TrackDetail />} />
          </Route> */}
          <Route path="pois">
            <Route path="" element={<PoisList />} />
            <Route path=":_id" element={<PoiDetail />} />
          </Route>
          <Route path="accomodations">
            <Route path="" element={<AccomodationsList />} />
            <Route path="openings" element={<AccomodationsListOpening />} />
            <Route path=":_id" element={<AccomodationDetail />} />
          </Route>
          <Route path="infos">
            <Route path="" element={<InfoPointLink />} />
            {/*             <Route path=":_id" element={<PoiDetail />} />
             */}{" "}
          </Route>
          {/* <Route path="transports">
            <Route path="" element={<TransportsList />} />
            <Route path=":_id" element={<TransportDetail />} />
          </Route> */}
          <Route path="users">
            <Route path="" element={<UsersList />} />
            {/* <Route path=":_id" element={<TransportDetail />} /> */}
          </Route>
          <Route path="/*" element={<Navigate to="/dashboard" />} />
        </Routes>
        <HStack
          w={"100%"}
          mt={2}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Text>
            Mio Trentino Business - © {new Date().getFullYear()} Trentino
            Marketing All rights reserved
          </Text>
        </HStack>
      </Box>
    </>
  );
};

const Router = () => {
  const { data: APP } = useQuery<APP_STATE_PROPS>(APP_STATE);

  if (APP?.secureInfos) {
    return (
      <Box flex={1} display={"flex"} height={"100%"} width={"100%"}>
        <PoisInfos />
      </Box>
    );
  }

  if (APP?.secure) {
    return (
      <Box flex={1} display={"flex"} height={"100%"} width={"100%"}>
        <Openings />
      </Box>
    );
  }

  if (APP?.user) {
    return (
      <Box flex={1}>
        <AdminRouter />
      </Box>
    );
  }

  return (
    <Box flex={1}>
      <AuthRouter />
    </Box>
  );
};

export { Router };
