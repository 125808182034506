import React from "react";
import { useMutation, useQuery } from "@apollo/client";

import {
  ACCOMODATIONS,
  ACCOMODATIONS_CALENDAR_ADDRANGE,
  //ACCOMODATIONS_GET_QR_EMAIL,
  ACCOMODATIONS_MIN,
  //ACCOMODATIONS_SECURE_MANAGE,
} from "stores/queries/accomodations";
import { ResourceTable } from "components/List/ResourceTable";
import { Resource } from "stores/queries/area";
import { OpeningTable } from "components/List/OpeningTable";

const AccomodationTableOpening = () => {
  const { loading, data } = useQuery(ACCOMODATIONS_MIN);

  const [addRange] = useMutation(ACCOMODATIONS_CALENDAR_ADDRANGE, {
    refetchQueries: [ACCOMODATIONS],
    //awaitRefetchQueries: true,
  });

  /*   const [secureManage] = useMutation(ACCOMODATIONS_SECURE_MANAGE, {
    refetchQueries: [{ query: ACCOMODATIONS }],
  }); */

  /*   const [getQrEmail, { loading: gqeLoading }] = useMutation(
    ACCOMODATIONS_GET_QR_EMAIL
  ); */

  const getQrEmail: any = () => {};
  const secureManage: any = () => {};

  return (
    <OpeningTable
      data={data?.adminAccomodations as unknown as Resource[]}
      loading={loading}
      addRange={addRange}
      title={"Apertura Strutture Ricettive"}
      itemPath={"/accomodations_openings/"}
      icon="my_hotel"
      type="accomodation"
    />
  );
};

export { AccomodationTableOpening };
