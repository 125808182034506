import { graphql } from "libs/apollo/__generated__";

const POI_FRAGMENT = graphql(`
  fragment poiFragment on Poi {
    _id
    name {
      langIso
      value
    }
    email
    statuses {
      manual {
        status
        lastUpdate
        motivation {
          langIso
          value
        }
        expiration
      }
      live {
        status
        updatedOrigin
        lastUpdate
      }
      daily {
        status
        updatedOrigin
        lastUpdate
      }
      calendar {
        status
        lastUpdate
      }
      external {
        status
        lastUpdate
      }
    }
    externalInfo {
      tgpId
      externalId
      externalSource
      updateLink
    }
    calendar {
      timeEncoding {
        times
      }
    }
    geoLocation {
      coordinatesMultiLineString
      coordinatesLineString
      coordinatesPoint
    }
    calendarMetadata {
      lastOperatorCheck
      isCalendarUpdated
      lastDay
      lastUpdate
      updatedBy
      threeMonthAlertSent
      threeMonthAlertDate
      emptyCalendarAlertSent
      emptyCalendarAlertDate
    }
    calendarSync {
      googleRefreshToken
      googleAccountId
      googleLocationId
      googleFirstSync
      googleLastSync
      googleLastSyncResult
      googleLastSyncError
      googleLastSyncErrorDate
      googleSyncInError
    }
    owner
    area
    secure {
      code
      lastRefresh
      permissions {
        manual
        calendar
      }
    }
    eventsCount
    filters
    termsAndConditions {
      accepted
      version
      newVersion
    }
  }
`);

const POI = graphql(`
  query adminPoi($_id: ID!) {
    adminPoi(_id: $_id) {
      ...poiFragment
    }
  }
`);

const POI_OPERATOR = graphql(`
  query OperatorPoi($id: ID!) {
    operatorPoi(_id: $id) {
      ...poiFragment
    }
  }
`);

const POIS = graphql(`
  query adminPois {
    adminPois {
      ...poiFragment
    }
  }
`);

const POIS_MIN = graphql(`
  query adminPoisMin {
    adminPois {
      _id
      name {
        langIso
        value
      }
      statuses {
        live {
          status
          updatedOrigin
          lastUpdate
        }
      }
      secure {
        code
        permissions {
          manual
          calendar
        }
      }
      externalInfo {
        updateLink
      }
      calendarMetadata {
        lastOperatorCheck
        isCalendarUpdated
        lastDay
        lastUpdate
        updatedBy
        threeMonthAlertSent
        threeMonthAlertDate
        emptyCalendarAlertSent
        emptyCalendarAlertDate
      }
      eventsCount
      filters
      email
      owner
      area
      emailSent
      termsAndConditions {
        accepted
        version
        newVersion
      }
    }
  }
`);

const POIS_AREA_MIN_RO = graphql(`
  query RoPoisByArea(
    $area: String!
    $offset: Int
    $limit: Int
    $date: Date
    $search: String
    $filters: PoiFilters
  ) {
    roPoisByArea(
      area: $area
      offset: $offset
      limit: $limit
      date: $date
      search: $search
      filters: $filters
    ) {
      poi {
        _id
        name {
          langIso
          value
        }
        statuses {
          live {
            status
            updatedOrigin
            lastUpdate
          }
          manual {
            status
            lastUpdate
            motivation {
              langIso
              value
            }
            expiration
          }
        }
        calendar {
          timeEncoding {
            times
          }
        }
        eventsCount
        filters
        email
        owner
        area
        emailSent
      }
      dailyTimehours
      weekOpeningDays
    }
  }
`);

const POI_CALENDAR = graphql(`
  query adminPoiGetCalendarDays($_id: ID!, $from: Date!, $to: Date!) {
    adminPoiGetCalendarDays(_id: $_id, from: $from, to: $to) {
      day
      schedules
    }
  }
`);

const POIS_CALENDAR_ADDRANGE = graphql(`
  mutation adminPoisCalendarAddRange(
    $_ids: [ID!]!
    $range: CalendarRangeInput
  ) {
    adminPoisCalendarAddRange(_ids: $_ids, range: $range) {
      _id
    }
  }
`);

export const POIS_CALENDAR_ADDRANGE_OPERATOR = graphql(`
  mutation operatorPoisCalendarAddRange(
    $_ids: [ID!]!
    $range: CalendarRangeInput
  ) {
    operatorPoisCalendarAddRange(_ids: $_ids, range: $range) {
      _id
    }
  }
`);

const POIS_STATUS_UPDATE = graphql(`
  mutation adminPoisUpdateStatus(
    $_ids: [ID!]!
    $status: Boolean
    $motivation: [LocalizationInput]
    $expiration: Date
  ) {
    adminPoisUpdateStatus(
      _ids: $_ids
      status: $status
      motivation: $motivation
      expiration: $expiration
    ) {
      _id
    }
  }
`);

export const POIS_STATUS_UPDATE_OPERATOR = graphql(`
  mutation operatorPoisUpdateStatus(
    $_ids: [ID!]!
    $status: Boolean
    $motivation: [LocalizationInput]
    $expiration: Date
  ) {
    operatorPoisUpdateStatus(
      _ids: $_ids
      status: $status
      motivation: $motivation
      expiration: $expiration
    ) {
      _id
    }
  }
`);

const POI_RANGE = graphql(`
  query poiGetTimeRangeInBaseOfTime($_id: ID!) {
    poiGetTimeRangeInBaseOfTime(_id: $_id)
  }
`);

const POI_CALENDAR_RESET = graphql(`
  mutation adminPoiCalendarReset($_id: ID!) {
    adminPoiCalendarReset(_id: $_id) {
      _id
    }
  }
`);

export const POI_CALENDAR_RESET_OPERATOR = graphql(`
  mutation operatorPoiCalendarReset($_id: ID!) {
    operatorPoiCalendarReset(_id: $_id) {
      _id
    }
  }
`);

const POIS_SECURE_MANAGE = graphql(`
  mutation adminPoisSecureManage($_ids: [ID!]!, $secure: SecureInput!) {
    adminPoisSecureManage(_ids: $_ids, secure: $secure) {
      _id
      secure {
        code
        permissions {
          manual
          calendar
        }
      }
    }
  }
`);

const POIS_GET_QR_PDF = graphql(`
  mutation AdminPoiGetQrCodePdf($_id: ID!) {
    adminPoiGetQrCodePdf(_id: $_id)
  }
`);

const POIS_GET_QR_EMAIL = graphql(`
  mutation AdminPoisSendEmail($_ids: [ID!]!) {
    adminPoisSendEmail(_ids: $_ids) {
      _id
      success
      errors
    }
  }
`);

const POI_ACCEPT_TERMS = graphql(`
  mutation AdminPoiAcceptTermsAndConditions($id: ID!) {
    adminPoiAcceptTermsAndConditions(_id: $id) {
      _id
    }
  }
`);

const POI_UPDATE = graphql(`
  mutation AdminPoiUpdate($id: ID!, $poi: PoiInput!) {
    adminPoiUpdate(_id: $id, poi: $poi) {
      _id
    }
  }
`);

const POIS_GET_STATS = graphql(`
  query AdminPoiGetStatistics {
    adminPoiGetStatistics {
      area
      statistics {
        total
        totalOpen
        totalClosed
        totalNd
        totalEditedByAdmin
        totalEditedByOperator
        totalNotEdited
        totalConditionsAccepted
      }
      emailStatistics {
        emptyCalendarAlertsLast30daysSent
        threeMonthAlerts30daysSent
      }
    }
  }
`);

const POIS_GET_ALLAREAS_STATS = graphql(`
  query PoiGetAllAreasStatistics {
    poiGetAllAreasStatistics {
      area
      statistics {
        total
        totalOpen
        totalClosed
        totalNd
        totalEditedByAdmin
        totalEditedByOperator
        totalNotEdited
        totalConditionsAccepted
      }
      emailStatistics {
        emptyCalendarAlertsLast30daysSent
        threeMonthAlerts30daysSent
      }
    }
  }
`);

const POI_GET_GOOGLE_AT_OPERATOR = graphql(`
  mutation OperatorPoiGetGoogleSyncAccessToken(
    $id: ID!
    $code: String!
    $redirectUrl: String
  ) {
    operatorPoiGetGoogleSyncAccessToken(
      _id: $id
      code: $code
      redirectUrl: $redirectUrl
    )
  }
`);

const POI_UPDATE_GOOGLE_SYNC_OPERATOR = graphql(`
  mutation OperatorPoiSaveGoogleSyncAccount(
    $id: ID!
    $googleSyncAccount: GoogleSyncAccountInput!
  ) {
    operatorPoiSaveGoogleSyncAccount(
      _id: $id
      googleSyncAccount: $googleSyncAccount
    ) {
      _id
    }
  }
`);

const POI_CANCEL_GOOGLE_SYNC_OPERATOR = graphql(`
  mutation OperatorPoiCancelGoogleSync($id: ID!) {
    operatorPoiCancelGoogleSync(_id: $id) {
      _id
    }
  }
`);

export {
  POI_FRAGMENT,
  POI,
  POI_OPERATOR,
  POI_UPDATE,
  POIS,
  POIS_MIN,
  POI_CALENDAR,
  POIS_CALENDAR_ADDRANGE,
  POIS_STATUS_UPDATE,
  POI_CALENDAR_RESET,
  POI_RANGE,
  POIS_SECURE_MANAGE,
  POIS_GET_QR_PDF,
  POIS_GET_QR_EMAIL,
  POI_ACCEPT_TERMS,
  POI_GET_GOOGLE_AT_OPERATOR,
  POI_UPDATE_GOOGLE_SYNC_OPERATOR,
  POI_CANCEL_GOOGLE_SYNC_OPERATOR,
  POIS_AREA_MIN_RO,
  POIS_GET_ALLAREAS_STATS,
  POIS_GET_STATS,
};
